<template>
  <PopoverSelector
    v-model="isOpen"
    :popover-title="$t('navigation.SELECT_REGION')"
    :popover-content-width="254"
    class="region-selector"
    :class="{ expanded: isSidebarExpanded }"
  >
    <template #button>
      <div class="button-layout" :class="{ expanded: isSidebarExpanded }">
        <IconEnvironment />

        <span>
          {{ selectedRegion }}
        </span>
      </div>
    </template>

    <div class="selector-body">
      <Btn v-for="region in Region.values" :key="region" unstyled style="width: 100%" @click="onRegionSelection(region)">
        <div class="selector-item" :class="{ selected: region === selectedRegion }">
          {{ region }}
        </div>
      </Btn>
    </div>
  </PopoverSelector>
</template>

<script setup lang="ts">
import Btn from '@/components/Btn.vue';
import PopoverSelector from '@/components/PopoverSelector.vue';
import IconEnvironment from '@/components/UI/Icons/Environment.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import { Region } from '@/models/region/Region';
import router from '@/router';
import { TESTPORTAL } from '@/router/route-types';

const route = useRoute();

const getRegionFromParams = () => {
  const regionParam = route.params['region'];
  return typeof regionParam === 'string' ? regionParam : Region.enum.EU;
};

const selectedRegion = ref<string>(getRegionFromParams());

watchEffect(() => {
  selectedRegion.value = getRegionFromParams();
});

const isOpen = ref(false);

const onRegionSelection = (region: Region) => {
  isOpen.value = false;

  if (region === selectedRegion.value) return;

  router.push({ name: TESTPORTAL, params: { region } });
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';

.region-selector {
  --popover-selector-arrow-padding: 4px;

  &.expanded {
    --popover-selector-arrow-padding: 24px;
  }
}

.button-layout {
  width: variables.$sidebar-mobile-width;

  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.expanded {
    width: variables.$sidebar-width;
    justify-content: flex-start;

    padding: 10px 20px;

    svg {
      display: initial;
      width: 20px;
      height: 20px;
    }
  }

  svg {
    display: none;
  }
}

.selector-body {
  --selector-text-color: light-dark(var(--color-ebony), var(--color-background-light-original));

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .selector-item {
    cursor: pointer;
    width: 100%;
    height: 56px;
    padding-left: 20px;

    border-radius: 4px;
    border: 1px solid var(--color-background-light);

    display: flex;
    align-items: center;

    &.selected {
      background-color: var(--color-background-light);
      color: var(--selector-text-color);
    }

    &:hover {
      background-color: var(--color-background-light);
    }
  }
}
</style>
