<template>
  <PopoverSelector v-model="isOpen" :popover-title="$t('navigation.SELECT_APPLICATION')" :popover-content-width="254">
    <template #button>
      <div class="button-surface">
        <span class="sr-only">Tool Selection</span>
      </div>
    </template>

    <div class="selector-body">
      <Btn unstyled style="width: 100%" @click="onToolSelection(Tool.enum.EXACT)">
        <div class="selector-item">
          <LogoExactWord />
          <span class="sr-only">exact</span>
        </div>
      </Btn>
      <Btn unstyled style="width: 100%" @click="onToolSelection(Tool.enum.TESTPORTAL)">
        <div class="selector-item">
          <LogoTestportalWord />
          <span class="sr-only">Test Portal</span>
        </div>
      </Btn>
    </div>
  </PopoverSelector>
</template>

<script setup lang="ts">
import Btn from '@/components/Btn.vue';
import PopoverSelector from '@/components/PopoverSelector.vue';
import LogoExactWord from '@/components/UI/logo/ExactWord.vue';
import LogoTestportalWord from '@/components/UI/logo/TestportalWord.vue';
import { Tool } from '@/models/tool/Tool';
import { LOGIN } from '@/router/route-types';
import { useSystemStore } from '@/stores/system';

const system = useSystemStore();
const router = useRouter();

const isOpen = ref(false);

const onToolSelection = (tool: Tool) => {
  isOpen.value = false;
  if (system.selectedTool === tool) return;
  system.resetAll();
  system.setSelectedTool(tool);
  router.push({ name: LOGIN });
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';

.logo-exact-word {
  color: var(--color-primary);
}

.logo-testportal-word {
  color: var(--color-ebony);
}

.button-surface {
  width: 60px;

  &:hover {
    background-color: var(--color-background-hover);
  }
}

.selector-body {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .selector-item {
    cursor: pointer;
    width: 100%;
    height: 56px;

    border-radius: 4px;
    border: 1px solid var(--color-background-light);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--color-background-light);
    }

    .logo-exact-word {
      width: 88px;
      height: 24px;
    }
  }
}
</style>
