<template>
  <ProjectSelector />

  <div class="divider" />

  <SidebarLinkList :items="navItemsPrimaryFlat" />

  <div class="divider" style="margin-top: 5px" />

  <SidebarLinkList :items="navItemsSecondary" />
</template>

<script setup lang="ts">
import ProjectSelector from '@/components/ProjectSelector.vue';
import SidebarLinkList from '@/components/UI/SidebarLinkList.vue';
import useNavigation from '@/composables/useNavigation';

const { navItemsPrimaryFlat, navItemsSecondary } = useNavigation();
</script>

<style scoped lang="scss">
.divider {
  padding-bottom: 5px;
}
</style>
