const TOOL = {
  EXACT: 'EXACT',
  TESTPORTAL: 'TESTPORTAL',
} as const;

export type Tool = keyof typeof TOOL;

export const Tool = {
  enum: TOOL,

  isTool(tool: any) {
    return tool in this.enum;
  },

  parse(tool: any) {
    if (this.isTool(tool)) return { error: false, tool: tool as Tool } as const;
    return { error: true } as const;
  },
};
