const REGION = {
  EU: 'EU',
} as const;

export type Region = keyof typeof REGION;

export const Region = {
  enum: REGION,

  get values() {
    return Object.values(this.enum);
  },

  isRegion(region: any) {
    return region in this.enum;
  },
};
