import { z } from 'zod';

export const ZodRoleEnum = z.enum([
  'PRIMARY_USER',
  'GUEST_USER',
  'SECONDARY_USER',
  'ANONYMOUS_USER',
  'ACCESS_VVG',
  'ACCESS_TEST_REPOSITORY',
  'ACCESS_TEST_PLANS',
  'ACCESS_REPORTING',
  'ACCESS_REPORTING_BUGS',
  'ACCESS_REPORTING_PROJECT_REPORTS',
  'ACCESS_SETTINGS',
  'ACCESS_SETTINGS_PROJECT_INFORMATION',
  'ACCESS_SETTINGS_TEST_REPOSITORY',
  'ACCESS_SETTINGS_TEST_REPOSITORY_PROJECT_SAVE',
  'ACCESS_SETTINGS_TOOLING',
  'ACCESS_SETTINGS_SECRETS',
  'ACCESS_SETTINGS_USERS',
  'ACCESS_SETTINGS_TEAMS',
  'ACCESS_SETTINGS_ENVIRONMENTS',
  'ACCESS_SETTINGS_TAG_GROUPS',
  'ACCESS_SETTINGS_JIRA_XRAY',
  'ACCESS_TESTPORTAL',
  'PROJECT_SELECT',
  'PROJECT_ADD',
]);
export const RoleEnum = ZodRoleEnum.enum;
export type RoleEnum = z.infer<typeof ZodRoleEnum>;
