<template>
  <svg class="icon-environment" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3475_23345)">
      <g clip-path="url(#clip1_3475_23345)">
        <path
          d="M11.9999 0.837262C18.1649 0.837262 23.1627 5.83505 23.1627 12.0001C23.1627 18.1651 18.1649 23.1628 11.9999 23.1628C5.83495 23.1628 0.837158 18.1651 0.837158 12.0001C0.837158 5.83505 5.83495 0.837262 11.9999 0.837262Z"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M0.837158 12H23.1627" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M12 0.837262C14.1579 0.837262 15.907 5.83505 15.907 12.0001C15.907 18.1651 14.1579 23.1628 12 23.1628C9.84209 23.1628 8.09302 18.1651 8.09302 12.0001C8.09302 5.83505 9.84209 0.837262 12 0.837262Z"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3475_23345">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
      <clipPath id="clip1_3475_23345">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
    </defs>
  </svg>
</template>
