export const DEMO = 'DEMO';
export const DEMO_LAYOUT = 'DEMO_LAYOUT';
export const DEMO_BUTTON = 'DEMO_BUTTON';
export const DEMO_UTILS = 'DEMO_UTILS';
export const DEMO_LAZYSTATE = 'DEMO_LAZYSTATE';
export const DEMO_TABLE = 'DEMO_TABLE';
export const DEMO_FORM_BUTTONBAR = 'DEMO_FORM_BUTTONBAR';
export const DEMO_FORM_USEFORM = 'DEMO_FORM_USEFORM';
export const DEMO_FORM_HELPERS = 'DEMO_FORM_HELPERS';
export const DEMO_FORM_UPLOAD = 'DEMO_FORM_UPLOAD';

export const ERROR = 'ERROR';
export const DASHBOARD = 'DASHBOARD';
export const LOGIN = 'LOGIN';
export const LOGIN_MOCK = 'LOGIN_MOCK';

export const USER = 'USER';
export const LOGOUT = 'LOGOUT';
export const PROFILE = 'PROFILE';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT';

export const AUTOMATION = 'AUTOMATION';
export const AUTOMATION_TEST_REPOSITORY = 'AUTOMATION_TEST_REPOSITORY';
export const AUTOMATION_TEST_REPOSITORY_TESTSET = 'AUTOMATION_TEST_REPOSITORY_TESTSET';
export const AUTOMATION_TESTPLAN_LIST = 'AUTOMATION_TESTPLAN_LIST';
export const AUTOMATION_TESTPLAN_DETAILS = 'AUTOMATION_TESTPLAN_DETAILS';
export const AUTOMATION_TESTPLAN_WEBHOOK_DOC = 'AUTOMATION_TESTPLAN_WEBHOOK_DOC';

export const REPORTING = 'REPORTING';
export const REPORTING_BUGS = 'REPORTING_BUGS';
export const REPORTING_OVERVIEW = 'REPORTING_OVERVIEW';
export const REPORTING_DETAILS = 'REPORTING_DETAILS';
export const REPORTING_POC = 'REPORTING_POC';
export const REPORTING_PROJECT_REPORTS = 'REPORTING_PROJECT_REPORTS';
export const REPORTING_PROJECT_REPORTS_CONFIG = 'REPORTING_PROJECT_REPORTS_CONFIG';
export const REPORTING_PROJECT_REPORTS_DETAILS = 'REPORTING_PROJECT_REPORTS_DETAILS';

export const PLATFORM = 'PLATFORM';
export const PLATFORM_VVG = 'PLATFORM_VVG';
export const PLATFORM_DETAILS = 'PLATFORM_DETAILS';

export const SETTINGS = 'SETTINGS';
export const SETTINGS_PROJECT_INFORMATION = 'SETTINGS_PROJECT_INFORMATION';
export const SETTINGS_TEST_REPOSITORY = 'SETTINGS_TEST_REPOSITORY';
export const SETTINGS_TOOLING = 'SETTINGS_TOOLING';
export const SETTINGS_SECRETS = 'SETTINGS_SECRETS';
export const SETTINGS_USERS = 'SETTINGS_USERS';
export const SETTINGS_TEAMS = 'SETTINGS_TEAMS';
export const SETTINGS_ENVIRONMENTS = 'SETTINGS_ENVIRONMENTS';
export const SETTINGS_TAG_GROUPS = 'SETTINGS_TAG_GROUPS';
export const SETTINGS_JIRA_XRAY = 'SETTINGS_JIRA_XRAY';

export const TESTPORTAL = 'TESTPORTAL';
export const TESTPORTAL_GARAGE = 'TESTPORTAL_GARAGE';
export const TESTPORTAL_MESSAGING = 'TESTPORTAL_MESSAGING';
export const TESTPORTAL_MESSAGING_MQTT = 'TESTPORTAL_MESSAGING_MQTT';
export const TESTPORTAL_MESSAGING_VEHICLE = 'TESTPORTAL_MESSAGING_VEHICLE';
export const TESTPORTAL_MONITORING = 'TESTPORTAL_MONITORING';
export const TESTPORTAL_MONITORING_MQTT = 'TESTPORTAL_MONITORING_MQTT';
