<template>
  <RegionSelector />

  <div class="divider" style="margin-bottom: 5px" />

  <SidebarLinkList :items="navItemsPrimaryTestportal" />

  <div v-for="(items, category) in navItemsCategoriesTestportal" :key="category">
    <div class="divider" style="margin: 5px 0" />

    <div class="category regular-md" :class="{ expanded: isSidebarExpanded }">
      <span class="ellipsis">
        {{ $t(`navigation.${category}`) }}
      </span>
    </div>

    <SidebarLinkList :items />
  </div>
</template>

<script setup lang="ts">
import RegionSelector from '@/components/RegionSelector.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import useNavigation from '@/composables/useNavigation';
import SidebarLinkList from './SidebarLinkList.vue';

const { navItemsPrimaryTestportal, navItemsCategoriesTestportal } = useNavigation();
</script>

<style scoped lang="scss">
.category {
  align-self: stretch;
  flex-shrink: 0;

  width: 100%;
  height: 32px;
  padding: 0 8px;
  color: var(--color-info);

  display: flex;
  align-items: center;
  gap: 8px;

  &.expanded {
    padding: 10px 20px 6px 20px;
  }
}
</style>
