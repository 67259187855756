<template>
  <div class="popover-selector">
    <ElPopover
      :visible="isOpen"
      popper-class="popover-selector"
      placement="right-start"
      :show-arrow="false"
      :offset="0"
      :width="widthPopover"
      :popper-options="{ modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }] }"
    >
      <template #reference>
        <OnClickOutside
          :options="{
            ignore: ['.el-popper'],
          }"
          @trigger="handleCancel"
        >
          <div>
            <ElTooltip :content="tooltipContent" placement="right" :disabled="!showTooltip || isOpen" transition="none" :show-after="400" :hide-after="0">
              <div
                class="button-selector focus-shadow-inset"
                role="button"
                tabindex="0"
                :class="{ isOpen }"
                @click="isOpen = !isOpen"
                @keypress.enter="isOpen = !isOpen"
              >
                <slot name="button" />

                <div class="arrow-container">
                  <ArrowDownSmallIcon :class="{ isOpen }" />
                </div>
              </div>
            </ElTooltip>
          </div>
        </OnClickOutside>
      </template>

      <div class="selector">
        <div class="title regular-lg">
          <div class="icon-headline">
            <slot name="icon" />
            <span>{{ popoverTitle }}</span>
          </div>

          <Btn only tertiary :svg-icon="CloseIcon" @click="handleCancel" />
        </div>

        <slot />
      </div>
    </ElPopover>
  </div>
</template>

<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import Btn from '@/components/Btn.vue';
import ArrowDownSmallIcon from '@/components/UI/Icons/ArrowDownSmall.vue';
import CloseIcon from '@/components/UI/Icons/Close.vue';

const isOpen = defineModel<boolean>();

interface PopoverSelectorProps {
  popoverContentWidth?: number;
  showTooltip?: boolean;
  popoverTitle: string;
  tooltipContent?: string;
}

const props = withDefaults(defineProps<PopoverSelectorProps>(), {
  popoverContentWidth: 300,
  tooltipContent: undefined,
});

// NOTE: Sync with css. Poppers are not part of the SFC. They're appended to the end of HTML body.
const padding = 20; // --popover-content-padding

const widthPopover = computed(() => {
  return padding * 2 + props.popoverContentWidth;
});

const handleCancel = () => {
  isOpen.value = false;
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';

.popover-selector {
  --popover-selector-arrow-padding: 24px;

  .button-selector {
    cursor: pointer;
    height: variables.$topbar-height;
    color: var(--color-primary);

    display: grid;
    & > * {
      grid-area: 1/1;
    }

    &:hover,
    &.isOpen {
      background-color: var(--color-background-hover);
    }

    .arrow-container {
      justify-self: end;
      align-self: center;
      padding-right: var(--popover-selector-arrow-padding);
      height: 12px;

      svg {
        width: 12px;
        height: 12px;

        &.isOpen {
          rotate: 180deg;
        }
      }
    }
  }
}

.selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;

  .title,
  .info {
    color: light-dark(var(--color-ebony), var(--color-background-light-original));
  }

  .title {
    align-self: stretch;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;

    .icon-headline {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        scale: calc(32 / 24); /* target-size / icon-size */
      }
    }
  }
}

:global(.popover-selector.el-popover.el-popper) {
  padding: var(--popover-content-padding, 20px);
  padding-top: 30px;

  border: none;
  border-radius: 20px;

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}
</style>
