<template>
  <LogoExactWord class="print" />
  <div class="header-wrapper no-print">
    <header class="header">
      <nav>
        <div class="logo-layout">
          <h1>
            <a :href="BASE_URL">
              <template v-if="system.selectedTool === Tool.enum.TESTPORTAL">
                <LogoTestportalWord />
                <span class="sr-only">{{ $t('navigation.selectedTool', { tool: 'Test Portal' }) }}</span>
              </template>
              <template v-else>
                <LogoExactWord />
                <span class="sr-only">{{ $t('navigation.selectedTool', { tool: 'exact' }) }}</span>
              </template>
            </a>
          </h1>

          <ToolSelector v-if="hasRole(RoleEnum.ACCESS_TESTPORTAL)" />
        </div>

        <DevSwitchMockApi v-if="hasMsw" />

        <div class="menu-right">
          <ElMenu
            mode="horizontal"
            :ellipsis="false"
            :default-active="activeName"
            router
            :default-openeds="activeName ? [activeName] : undefined"
            @select="() => toggleDark()"
          >
            <ElMenuItem v-if="isLoggedIn" index="color" :route="{}">
              <template #title>
                <Btn
                  unstyled
                  :svg-icon="isDark ? IconModeLight : IconModeDark"
                  only
                  tabindex="-1"
                  :title="toggleTranslation"
                  placement="bottom"
                  :show-after="500"
                >
                  <span class="sr-only">{{ toggleTranslation }}</span>
                </Btn>
              </template>
            </ElMenuItem>

            <ElSubMenu v-if="Object.keys(externalLinks).length" index="GRID" class="no-arrow">
              <template #title>
                <IconGrid />
              </template>

              <ElMenuItem v-for="(url, key) in externalLinks" :key :index="key" :route="{}">
                <template #title>
                  <a :href="url" target="_blank" class="external">{{ $to(key, 'navigation.external.') }}</a>
                </template>
              </ElMenuItem>
            </ElSubMenu>

            <ElSubMenu v-if="isLoggedIn" index="USER" class="no-arrow">
              <template #title>
                <IconUser />
                <span class="sr-only">{{ $t('navigation.USER') }}</span>
              </template>

              <ElMenuItemGroup>
                <template #title>
                  <FontAwesomeIcon :icon="faUser" transform="left-8" />
                  {{ fullName }}
                </template>

                <ElMenuItem v-for="item in visibleUserNavigationItems" :key="item" :index="item" :route="{ name: item }">
                  <template #title>{{ $to(item, 'navigation.') }}</template>
                </ElMenuItem>
              </ElMenuItemGroup>
            </ElSubMenu>
          </ElMenu>
        </div>
      </nav>
    </header>
  </div>
</template>

<script setup lang="ts">
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useDark, useToggle } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Btn from '@/components/Btn.vue';
import ToolSelector from '@/components/ToolSelector.vue';
import IconGrid from '@/components/UI/Icons/Grid.vue';
import IconModeDark from '@/components/UI/Icons/ModeDark.vue';
import IconModeLight from '@/components/UI/Icons/ModeLight.vue';
import IconUser from '@/components/UI/Icons/User.vue';
import LogoExactWord from '@/components/UI/logo/ExactWord.vue';
import LogoTestportalWord from '@/components/UI/logo/TestportalWord.vue';
import useNavigation from '@/composables/useNavigation';
import { Tool } from '@/models/tool/Tool';
import { RoleEnum } from '@/models/user/RoleEnum';
import { i18n } from '@/plugins/vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { visibleUserNavigationItems } from '@/utils/navigation';
import { hasRole } from '@/utils/roles';
import DevSwitchMockApi from './DevSwitchMockApi.vue';

const BASE_URL = import.meta.env.BASE_URL;

const hasMsw = import.meta.env.DEV || import.meta.env.MODE === 'msw';

const system = useSystemStore();
const authStore = useAuthStore();
const { activeName } = useNavigation();
const { isLoggedIn, fullName } = storeToRefs(authStore);

const isDark = useDark();
const toggleDark = useToggle(isDark);
const toggleTranslation = computed(() => (isDark.value ? i18n.t('navigation.toggleColorTheme.dark') : i18n.t('navigation.toggleColorTheme.light')));

const { externalLinks } = system;
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

h1 {
  width: calc(variables.$sidebar-width - variables.$topbar-height + 10px);

  margin: 0;
  padding: 0;
  line-height: 0;

  a {
    width: 100%;
    height: 100%;
    padding-left: 26px;

    display: flex;
    align-items: center;
  }
}

.header-wrapper {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  .header {
    width: 100%;
    height: variables.$topbar-height;
    background-color: light-dark(var(--color-background-light), var(--color-background-darker));
    background-color: var(--color-background);

    nav {
      display: flex;
      justify-content: space-between;
      gap: 0;

      .logo-layout {
        display: flex;
      }

      .mock-toggle {
        margin-left: auto;
        padding: 0 10px;

        display: flex;
        align-items: center;
      }

      .menu-right {
        * {
          margin: 0;
          padding: 0;
        }

        li > *:first-child {
          height: 100%;
          padding: 10px;
        }
      }

      .menu-left,
      .menu-right {
        :deep(.btn),
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: var(--el-text-color-regular);
          font-size: 14px;
        }
      }

      .menu-right {
        display: flex;
        margin: 0 20px;

        .el-menu-item {
          border-bottom: none;

          & > span {
            height: 100%;
          }
        }

        :deep(.el-menu--horizontal > .el-sub-menu .el-sub-menu__title) {
          border-bottom: none;
        }

        :deep(.el-sub-menu.no-arrow.is-active) {
          .el-sub-menu__title {
            color: var(--color-ebony);
          }
        }
      }
    }

    .el-sub-menu.no-arrow {
      :deep(.el-sub-menu__icon-arrow) {
        display: none;
      }
    }

    .el-menu--horizontal.el-menu {
      border-bottom: none;
    }
  }
}

.external {
  display: flex;
  flex-grow: 1;
  margin: 0 -10px;
  padding: 0 10px;
  color: var(--el-menu-text-color);
  text-decoration: none;
  transition: all var(--el-transition-duration-fast);

  &:hover {
    color: var(--el-menu-hover-text-color);
    background-color: var(--el-menu-hover-bg-color);
  }
}

.print {
  align-self: flex-end;
  position: absolute;
  display: none;
  margin: 0 20px;
  height: variables.$topbar-height;
  color: var(--color-primary);
}

@media print {
  .print {
    display: initial;
  }
}
</style>
