<template>
  <aside v-if="isLoggedIn" class="sidebar no-print" :class="{ expanded: isSidebarExpanded }">
    <nav>
      <TestportalSidebar v-if="system.selectedTool === Tool.enum.TESTPORTAL" />
      <ExactSidebar v-else />

      <div class="divider" style="margin-top: 5px" />

      <SidebarExpander />
    </nav>
  </aside>
  <div v-else class="sidebar" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import ExactSidebar from '@/components/UI/ExactSidebar.vue';
import SidebarExpander from '@/components/UI/SidebarExpander.vue';
import TestportalSidebar from '@/components/UI/TestportalSidebar.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import { Tool } from '@/models/tool/Tool';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';

const system = useSystemStore();

const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

.sidebar {
  width: variables.$sidebar-mobile-width;

  .spacer {
    width: variables.$sidebar-mobile-width;
  }

  nav {
    display: flex;
    flex-direction: column;

    width: variables.$sidebar-mobile-width;
    overflow-y: auto;
    padding-bottom: 5px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: var(--color-background);

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .divider {
      margin-bottom: 5px;
      height: 1px;
      align-self: stretch;
      background: var(--Primary-hover-light, #e2deff);
    }
  }

  &.expanded {
    width: variables.$sidebar-width;

    .spacer {
      width: variables.$sidebar-width;
    }

    nav {
      width: variables.$sidebar-width;
    }
  }
}
</style>
