<script lang="ts">
import { type Component, mergeProps, type PropType, type Ref } from 'vue';
import { type RouteLocationRaw, useLink } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ElTooltip } from 'element-plus/es';
import Close from '@/components/UI/Icons/CloseSmall.vue';
import useSlot from '@/composables/useSlot';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

export default defineComponent({
  inheritAttrs: false,

  props: {
    disabled: Boolean,
    secondary: Boolean,
    // positive: Boolean,
    // negative: Boolean,
    small: Boolean,
    tertiary: Boolean,
    quaternary: Boolean,
    only: Boolean,
    unstyled: Boolean,
    chip: Boolean,
    closable: Boolean,
    more: Boolean,
    submit: Boolean,

    tag: {
      type: String,
      default: 'button',
    },

    label: {
      type: [Number, String],
      default: null,
    },

    title: {
      type: String,
      required: false,
      default: null,
    },

    placement: {
      type: String as PropType<import('@popperjs/core').Placement>,
      required: false,
      default: 'top',
    },

    showAfter: {
      type: Number,
      required: false,
      default: 1000,
    },

    /** Forces tag to 'a' */
    href: {
      type: String,
      default: null,
    },

    /** Forces tag to 'a' */
    to: {
      type: Object as PropType<RouteLocationRaw | Ref<RouteLocationRaw>>,
      required: false,
      default: null,
    },

    exactActiveClass: {
      type: String,
      required: false,
      default: 'router-link-exact-active',
    },

    ariaHidden: Boolean,

    /** Will add class "active" */
    active: Boolean,

    /** Will add aria-selected="true/false" and if selected: show a close-icon and append a sr-only span " (currently selected)" */
    selected: {
      type: Boolean,
      required: false,
      // eslint-disable-next-line vue/no-boolean-default
      default: null,
    },

    svgIcon: {
      type: [Function, Object] as PropType<Component>,
      required: false,
      default: null,
    },

    faIcon: {
      type: Object as PropType<IconDefinition>,
      required: false,
      default: null,
    },

    outerStyle: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props, { slots }) {
    const { href, isActive, isExactActive, navigate } = props.to ? useLink(props) : ({} as any);
    const btn = ref<HTMLButtonElement | null>(null);

    const optionalVisibleSlotEntries = computed(() => {
      if (props.label) return [];
      const { visibleSlotEntries } = useSlot(slots.default);
      return visibleSlotEntries.value;
    });

    // Is the requested button a link?
    const isLink = computed(() => Boolean(props.href || props.to || (props.tag && String(props.tag).toLowerCase() === 'a')));

    // Is the button "really" a button?
    const isButton = computed(() => {
      if (isLink.value) return false;
      return !(props.tag && String(props.tag).toLowerCase() !== 'button');
    });

    const focus = () => {
      btn.value?.focus();
    };

    return { routerHref: href, navigate, isActive, isExactActive, optionalVisibleSlotEntries, isLink, isButton, btn, focus };
  },

  render() {
    const children = [...this.optionalVisibleSlotEntries];

    const hasLabel = !!(this.label || this.optionalVisibleSlotEntries?.length);

    if (this.svgIcon) {
      children.unshift(h('span', { class: ['icon', 'svg-icon'] }, h(this.svgIcon)));
    }

    if (this.faIcon) {
      children.unshift(h(FontAwesomeIcon, { class: ['icon'], icon: this.faIcon }));
    }

    if (this.label) {
      children.push(h('span', { innerHTML: this.$to(this.label) }));
    }

    if (this.title) {
      children.push(h('span', { class: 'sr-only' }, [this.title]));
    }

    if (this.selected) {
      children.push(h('span', { class: 'sr-only' }, ' (' + this.$t('common.currently_active') + ')'));
      if (this.chip && this.closable) children.push(h(Close));
    }

    const title = this.title || (this.label && this.$tt(this.label));

    const props = mergeProps(this.$attrs, {
      ref: 'btn',
      class: [
        'btn',
        'no-print',
        {
          active: this.active || this.isActive,
          disabled: this.disabled,
          selected: this.selected,
          secondary: this.secondary,
          // positive: this.positive,
          // negative: this.negative,
          small: this.small,
          tertiary: this.tertiary,
          quaternary: this.quaternary,
          only: this.only,
          unstyled: this.unstyled || this.quaternary,
          chip: this.chip,
          more: this.more,
          [this.exactActiveClass]: this.isExactActive,
          'only-icon': (!hasLabel && (this.faIcon || this.svgIcon)) || this.only,
        },
      ],
    });
    if (this.ariaHidden) {
      props.ariaHidden = true;
      props.tabindex = -1;
    }
    if (this.selected !== null) {
      props.ariaSelected = this.selected;
    }
    if (this.isButton) {
      // Type only used for "real" buttons
      props.type = this.submit ? 'submit' : 'button';
    }
    if (this.disabled && this.isButton) {
      // Disabled only set on "real" buttons
      props.disabled = true;
    }
    if (this.disabled) {
      props.onClick = e => {
        /* istanbul ignore if: blink/button disabled should handle this */
        if (e instanceof Event) {
          e.stopPropagation();
          e.preventDefault();
        }
      };
    }
    if (this.href && !this.disabled) {
      props.href = this.href;
    }

    const tag = this.isLink ? 'a' : this.isButton ? 'button' : this.tag;

    const wrapWithTooltip = (cb: () => VNode) =>
      h(
        'span',
        { style: { display: 'inline-flex', ...this.outerStyle } },
        h(ElTooltip, { content: title || '', placement: this.placement, showAfter: this.showAfter, triggerKeys: ['Space'] }, cb)
      );

    if (this.to) {
      const routerLinkEl = h(resolveComponent('RouterLink'), { to: this.to, custom: true }, () =>
        h(tag, mergeProps(props, { href: this.disabled ? undefined : this.href || this.routerHref, onClick: this.navigate }), children)
      );

      if (title) {
        return wrapWithTooltip(() => h('span', { style: { display: 'inline-flex' } }, routerLinkEl));
      }
      return routerLinkEl;
    }

    const el = () => h(tag, props, children);

    if (title) {
      return wrapWithTooltip(el);
    }

    return el();
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

.btn {
  user-select: none;
  white-space: nowrap;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  min-width: 128px;
  height: 40px;
  padding: 0 15px;
  overflow: visible;
  font-family: variables.$font-bold;
  //font-weight: 700; // quick-fix for firefox on mac
  color: var(--color-button-text-primary);
  text-decoration: none;
  cursor: pointer;
  background-color: var(--color-button-bg-primary);
  border: 1px solid var(--color-button-bg-primary);
  transition: var(--el-transition-duration-fast);

  &:hover {
    color: var(--color-button-text-hover-primary);
    background-color: var(--color-button-bg-hover-primary);
    border-color: var(--color-button-bg-hover-primary);
  }
  &:focus-visible {
    background-color: var(--color-button-bg-hover-primary);
    outline: 4px solid var(--el-text-color-primary);
    outline-offset: 0;
  }
  &:active {
    background-color: var(--color-button-bg-active-primary);
    border-color: var(--color-button-bg-active-primary);
  }

  &.secondary:not(.disabled) {
    color: var(--color-button-text);
    background-color: var(--color-button-bg);
    border-color: var(--color-button-text);

    &:hover,
    &:focus-visible {
      background-color: var(--color-button-bg-hover);
      color: var(--color-button-text-hover);
      border-color: var(--color-button-border-hover);
    }
    &:active {
      border-color: var(--color-button-border-active);
    }
  }

  &.tertiary {
    min-width: unset;
    background: none;
    color: var(--color-primary);
    border: 2px solid rgba(0, 0, 0, 0);
    transition: all var(--el-transition-duration-fast);
    gap: 6px;

    &:hover {
      color: var(--color-button-text-hover-primary);
      background-color: var(--color-background-light);
    }

    &:hover,
    &:active {
      color: var(--color-button-text-hover);
    }
  }

  &:disabled,
  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    color: light-dark(var(--color-button-text-disabled), var(--color-primary-dark-popover));
    background-color: light-dark(var(--color-disabled-light), var(--color-primary-darker));
    border-color: transparent;
  }

  .svg-icon {
    svg {
      align-items: center;
      display: flex;
    }
  }

  &.small {
    height: 32px;
    gap: 6px;
    font-size: 14px;
    box-shadow: none;
  }

  &:not(.chip).selected {
    background-color: var(--color-button-bg-active-primary);
    border: none;
    outline-offset: 2px;
  }

  &.unstyled,
  &.unstyled:hover,
  &[type='submit'].unstyled,
  &[type='reset'].unstyled {
    transition: none;
    display: unset;
    font-weight: inherit;
    font-family: inherit;
    min-width: 0;
    height: auto;
    background: none;
    box-shadow: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    &.disabled {
      background-color: unset;
      color: var(--el-disabled-text-color); // var(--color-button-text-disabled);
      cursor: no-drop;
    }
    &.only-icon {
      display: flex;

      &:focus-visible {
        outline-offset: 1px;
      }
    }
  }

  &.quaternary {
    min-width: unset;
    font-size: inherit;
    padding: 0 2px;
    color: var(--color-primary);
    transition: unset;
    background-color: unset;
    border: unset;
    font-weight: inherit;
    font-family: inherit;
    text-decoration: underline;

    & > svg {
      padding-right: 5px;
    }

    &:hover {
      font-size: inherit;
      padding: 0 2px;
      background-color: unset;
      color: var(--color-button-text-hover);
    }
    &:active {
      color: var(--color-button-text-hover);
    }

    &[disabled],
    &:disabled,
    &.disabled {
      &,
      &:hover {
        color: var(--el-text-color-disabled);
        background-color: unset;
      }
    }
  }

  &.chip,
  &.more {
    &.small {
      height: 24px;
    }
  }

  &.chip,
  &.more {
    min-width: unset;
    gap: 6px;
    padding: 4px 10px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    color: var(--color-primary);
    background-color: var(--color-background);
    transition: all var(--el-transition-duration-fast);
    font-size: 11px;
    font-family: variables.$font-regular;
    font-weight: 400;
    line-height: normal;

    &:active {
      color: var(--el-text-color-primary);
      border-color: var(--el-text-color-primary);
    }

    &:hover {
      border-color: var(--color-button-border-hover);
      color: var(--color-button-border-hover);

      &.selected {
        background-color: var(--color-background-hover);
      }
    }

    &.selected {
      background-color: var(--color-background-light);
    }

    &:focus-visible {
      outline-offset: 2px;
    }
  }

  &.more {
    border-color: var(--color-primary);
    font-family: variables.$font-bold;
    //font-weight: 700; // quick-fix for firefox on mac

    &:hover {
      background-color: var(--color-background-hover);
    }
  }

  &:not(.unstyled).only-icon {
    min-width: auto;
    &.only {
      height: auto;
      width: auto;
      background-color: inherit;
      border-radius: unset;
      border: none;
      color: var(--color-primary);
      padding: 0;

      &:hover {
        color: var(--color-button-border-hover);
      }
      &:active {
        color: var(--el-text-color-primary);
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    &.small {
      min-width: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
