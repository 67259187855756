<template>
  <svg class="logo-exact-word" width="104" height="30" viewBox="0 0 104 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M51.9719 17.6714H42.1354C42.2034 18.5533 42.4868 19.2266 42.9878 19.6913C43.4865 20.1583 44.1009 20.3896 44.8309 20.3896C45.9145 20.3896 46.6694 19.9316 47.0933 19.018H51.718C51.48 19.9498 51.0538 20.7886 50.4349 21.5344C49.816 22.2803 49.0407 22.8651 48.109 23.2891C47.1772 23.713 46.1344 23.9238 44.9827 23.9238C43.5931 23.9238 42.3553 23.6269 41.2716 23.0352C40.188 22.4412 39.3402 21.5956 38.7303 20.4939C38.1205 19.3921 37.8145 18.1044 37.8145 16.6309C37.8145 15.1573 38.116 13.8696 38.7167 12.7679C39.3175 11.6661 40.1608 10.8205 41.2467 10.2266C42.3303 9.6326 43.5749 9.33789 44.9827 9.33789C46.3906 9.33789 47.5762 9.6258 48.6417 10.2016C49.7095 10.7774 50.5437 11.6004 51.1445 12.6681C51.7452 13.7359 52.0467 14.9805 52.0467 16.4042C52.0467 16.81 52.0218 17.2339 51.9697 17.676L51.9719 17.6714ZM47.6011 15.2571C47.6011 14.5112 47.3472 13.9195 46.8394 13.4775C46.3316 13.0377 45.6946 12.8178 44.9329 12.8178C44.1712 12.8178 43.5908 13.0309 43.0898 13.4525C42.5888 13.8764 42.2805 14.4772 42.1626 15.2571H47.6011Z"
        fill="currentColor"
      />
      <path
        d="M60.6901 23.7195L57.9697 19.7795L55.6822 23.7195H50.9805L55.6573 16.4764L50.8535 9.53711H55.7344L58.4548 13.45L60.7422 9.53711H65.444L60.6923 16.6782L65.5732 23.7173H60.6923L60.6901 23.7195Z"
        fill="currentColor"
      />
      <path
        d="M65.2057 12.7659C65.7566 11.6642 66.507 10.8186 67.4546 10.2246C68.4045 9.63065 69.4632 9.33594 70.633 9.33594C71.6327 9.33594 72.5101 9.53997 73.2627 9.94576C74.0176 10.3516 74.598 10.8866 75.0038 11.5463V9.5377H79.3496V23.7201H75.0038V21.7116C74.5798 22.3713 73.9904 22.9063 73.2378 23.3121C72.4828 23.7179 71.6078 23.9219 70.608 23.9219C69.4564 23.9219 68.4045 23.6204 67.4569 23.0196C66.507 22.4189 65.7589 21.5619 65.208 20.4534C64.6571 19.3448 64.3828 18.0594 64.3828 16.6017C64.3828 15.144 64.6571 13.8654 65.208 12.7637L65.2057 12.7659ZM74.1015 14.0627C73.5007 13.437 72.7662 13.1218 71.9025 13.1218C71.0388 13.1218 70.3042 13.4302 69.7035 14.0491C69.1027 14.6679 68.8012 15.5203 68.8012 16.604C68.8012 17.6876 69.1027 18.5491 69.7035 19.1838C70.3042 19.8186 71.0388 20.136 71.9025 20.136C72.7662 20.136 73.4985 19.8231 74.1015 19.1952C74.7023 18.5695 75.0038 17.7125 75.0038 16.6289C75.0038 15.5453 74.7023 14.6884 74.1015 14.0627Z"
        fill="currentColor"
      />
      <path
        d="M81.1366 12.7659C81.7374 11.6642 82.5762 10.8186 83.653 10.2246C84.7299 9.63065 85.9608 9.33594 87.3505 9.33594C89.1301 9.33594 90.6173 9.80294 91.812 10.7347C93.0067 11.6664 93.7911 12.979 94.1629 14.6747H89.5382C89.1483 13.5911 88.3933 13.047 87.2757 13.047C86.48 13.047 85.843 13.3553 85.3692 13.9742C84.8953 14.5931 84.6573 15.4773 84.6573 16.6312C84.6573 17.7851 84.8953 18.6692 85.3692 19.2881C85.843 19.907 86.48 20.2153 87.2757 20.2153C88.3933 20.2153 89.1483 19.6735 89.5382 18.5876H94.1629C93.7888 20.2493 93.0022 21.5529 91.7984 22.5027C90.5946 23.4526 89.112 23.9264 87.3505 23.9264C85.9608 23.9264 84.7276 23.6294 83.653 23.0378C82.5762 22.4438 81.7374 21.5982 81.1366 20.4964C80.5359 19.3947 80.2344 18.107 80.2344 16.6334C80.2344 15.1599 80.5359 13.8722 81.1366 12.7705V12.7659Z"
        fill="currentColor"
      />
      <path
        d="M103.227 20.0355V23.7216H101.016C99.4409 23.7216 98.2121 23.3363 97.3303 22.5655C96.4484 21.7947 96.0086 20.5365 96.0086 18.7909V13.1483H94.4512V9.53922H96.0086V6.08203H100.354V9.53922H103.202V13.1483H100.354V18.8408C100.354 19.2647 100.456 19.5685 100.661 19.7566C100.865 19.9425 101.202 20.0355 101.676 20.0355H103.227Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M28.1062 12.3274L25.0571 14.0866C25.3948 18.1673 23.4157 22.2774 19.6412 24.456C14.427 27.4665 7.73709 25.6733 4.7265 20.4592C1.71591 15.2451 3.50911 8.55513 8.72324 5.54454C13.4568 2.81053 19.4077 4.03925 22.7175 8.19014L20.0651 9.72038C17.6235 6.99316 13.527 6.25185 10.2308 8.15614C6.44943 10.3393 5.15496 15.1725 7.33809 18.9516C9.52122 22.733 14.3545 24.0275 18.1359 21.8444C20.4119 20.5295 21.7858 18.2557 22.0442 15.8277L16.5082 19.0242C14.291 20.3005 11.4459 19.5388 10.1651 17.3217C8.8842 15.1045 9.64818 12.2594 11.8653 10.9786C13.586 9.98562 15.683 10.2214 17.1338 11.4138L13.3706 13.5879C12.5908 14.039 12.3233 15.0365 12.7744 15.8164C13.2255 16.5962 14.223 16.8637 15.0029 16.4126L21.7019 12.5451L24.3543 11.0148L27.2039 9.37126C27.1971 9.35539 27.1903 9.33952 27.1835 9.32365C27.1427 9.2307 27.1019 9.14002 27.0588 9.04708C27.0475 9.01987 27.0339 8.99493 27.0226 8.96773C26.9772 8.87478 26.9319 8.77957 26.8865 8.68662C26.8729 8.66168 26.8616 8.63448 26.848 8.60954C26.8004 8.51659 26.7528 8.42365 26.7029 8.3307C26.6893 8.30576 26.6757 8.28083 26.6621 8.25589C26.5986 8.14027 26.5351 8.02465 26.4694 7.9113C22.5452 1.13067 13.8739 -1.19302 7.09099 2.72211C0.310359 6.63724 -2.01333 15.3108 1.9018 22.0915C5.81693 28.8744 14.4905 31.198 21.2711 27.2806C26.644 24.1794 29.2148 18.0924 28.1062 12.3274Z"
      fill="currentColor"
    />
  </svg>
</template>
